import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $heading = $el.find('.article-header .heading-wrapper');
    const $blocks = $el.find('.scroll-block');
    
    const init = () => {
        //$(el).find('*').css({ opacity: 1 });
        gsap.set($heading.nodes, { y: 40 });
        gsap.set($blocks.nodes, { y: 40 });

        gsap.to($heading.nodes, { duration: 1, delay: 0.2, opacity: 1, ease: 'Linear.easeNone' });
        gsap.to($heading.nodes, { duration: 1.4, delay: 0.2, y: 0, ease: 'Quart.easeOut' });
        
        gsap.to($blocks.nodes, { duration: 1, delay: 0.3, opacity: 1, ease: 'Linear.easeNone' });
        gsap.to($blocks.nodes, { duration: 1.4, delay: 0.3, y: 0, ease: 'Quart.easeOut' });
        
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
