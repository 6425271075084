import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $top_bar = $el.find('.top-bar');
    const $bottom_bar = $el.find('.bottom-bar');

    const init = () => {
        //$(el).find('*').css({ opacity: 1 });
        gsap.set($top_bar.nodes, { y: -100, opacity: 1 });
        gsap.set($bottom_bar.nodes, { y: 100, opacity: 1 });

        gsap.to([$top_bar.nodes, $bottom_bar.nodes], { duration: 1.4, delay: 0.5, y: 0, ease: 'Quart.easeOut' });
        
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
