import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    
    const $el = $(el);
    const $orderBtn = $el.find('[data-order]');
    const $products = $el.find('[data-product]');
    
    const order = () => {
        let added = false;
        
        $products.each(item => {
            const $item = $(item);
            const $adder = $item.find('.snipcart-add-item');
            const qty = $item.find('[data-quantity-input]').val();
            
            if (qty > 0) {
                console.log('her', qty);
                $adder.attr('data-item-quantity', qty);
                $adder.get(0).click();
                added = true;
                $item.find('[data-quantity-input]').val('0');
            }
        });
        
        if (added) {
            Snipcart.api.theme.cart.open();
        }
    };
    
    const init = () => {
        /*
        setTimeout(() => {
            try {
                Snipcart.api.cart.items.add({
                    id: 'lorem-ipsum',
                    name: 'Lorem ipsum',
                    price: 1.11,
                    url: '/',
                    quantity: 1,
                });
            } catch (error) {
                console.log(error)
            }
        }, 500);
        
        setTimeout(() => {
            Snipcart.api.theme.cart.open();
        }, 2000);
        */
        $orderBtn.on('click', e => {
            e.preventDefault();
            order();
        })
        
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
