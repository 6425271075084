import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    const $el = $(el);
    const $imageBlock = $el.find('.image-block');
    const $logo = $el.find('.logo');
    const $stars = $el.find('.stars');
    
    const init = () => {
        //$(el).find('*').css({ opacity: 1 });
        gsap.set($logo.nodes, { y: -50 });
        gsap.set($stars.nodes, {  });

        gsap.to($imageBlock.nodes, { duration: 0.8, delay: 0.3, opacity: 1, ease: 'Linear.easeNone' });

        gsap.to($logo.nodes, { duration: 1.1, delay: 1.6, opacity: 1, ease: 'Linear.easeNone' });
        gsap.to($logo.nodes, { duration: 1.6, delay: 1.6, y: 0, ease: 'Quint.easeOut' });

        gsap.to($stars.nodes, { duration: 1, delay: 2.5, opacity: 1, ease: 'Linear.easeNone' });

    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
